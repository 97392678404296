import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import LogoLink from 'components/navigation/LogoLink';
import LoginDropdownContainer from 'containers/navigation/LoginDropdownContainer';
import { locale as i18n } from 'instances/i18n';
import CodeModel from 'models/code';
import Button from 'components/Button';
import { history } from 'byebye';
import ApplicationState from 'instances/application_state';
import BrowserEnv from 'instances/browser_environment';
import classNames from 'classnames';
import { setAccessCookie } from 'helpers/internationalLaunch';

export default class AccessPage extends React.Component {
  static propTypes = {
    code: PropTypes.string,
  };

  state = {
    formState: {},
    checkingFromUrl: false,
  };

  render() {
    const className = classNames('access-page', {
      hidden: this.state.checkingFromUrl,
    });

    return (
      <div className={className}>
        <div className="v-primary-navigation">
          <LogoLink />
          <div className="access-login">
            <LoginDropdownContainer />
          </div>
        </div>
        <div className="background">
          <div className="content-container">
            <h1>{i18n.accesspage.header}</h1>
            <h2>{i18n.accesspage.subheader}</h2>
            <form method="GET" className="frm frm-access" onSubmit={this._onSubmit.bind(this)}>
              <p dangerouslySetInnerHTML={{ __html: i18n.accesspage.form.title }} />
              <div className="input-wrapper">
                <input
                  autoCorrect="off"
                  autoComplete="off"
                  className="inp inp-text inp-accesskey"
                  maxLength="6"
                  placeholder={i18n.accesspage.form.placeholder}
                  ref="code"
                  name="code"
                  onChange={this._onChange.bind(this)}
                />
                {this._renderError()}
                <Button
                  className="btn-unlock btn-green"
                  loading={this.state.formState.type === 'loading'}
                >
                  <span className="icon-unlock" />
                  {i18n.accesspage.form.button}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <p className="lnk-invite" dangerouslySetInnerHTML={{ __html: i18n.accesspage.noinvite }} />
      </div>
    );
  }

  componentDidMount() {
    if (this.props.code) {
      ReactDOM.findDOMNode(this.refs.code).value = this.props.code;
    }
  }

  _renderError() {
    if (this.state.formState.type === 'error') {
      return <p className="error-message">{this.state.formState.message}</p>;
    }
  }

  _onChange() {
    const value = ReactDOM.findDOMNode(this.refs.code).value;
    this.setState({
      formState: {},
    });

    ReactDOM.findDOMNode(this.refs.code).value = value;
  }

  _checkCode(code) {
    if (code === '') {
      this.setState({
        formState: {
          type: 'error',
          message: i18n.app.error.default_form_field_required,
        },
      });
      return;
    }

    this.setState({
      formState: { type: 'loading' },
    });

    const model = new CodeModel();
    model.set('code', code);
    model.fetch().then(
      () => {
        if (!model.get('used')) {
          setAccessCookie();
          ApplicationState.set('signUpCode', model);
          history.navigate('signup/kiosk', { trigger: true });
        } else {
          this.setState({
            formState: { type: 'error', message: i18n.accesspage.form.error },
            checkingFromUrl: false,
          });
        }
      },
      () => {
        this.setState({
          formState: { type: 'error', message: i18n.accesspage.form.error },
          checkingFromUrl: false,
        });
      },
    );
  }

  _onSubmit(e) {
    e.preventDefault();

    const code = ReactDOM.findDOMNode(this.refs.code).value.trim();
    this._checkCode(code);
  }
}
